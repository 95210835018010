// Style
import '../styles/Infos.css';

function Remboursement() {
    return (
        <div className='center-content'>
            <h1>Politique de remboursement</h1>
            <h3>ARTICLE 1 : DROIT DE RÉTRACTATION - RETOUR </h3>
            <p>
                Le client dispose d'un délai de quatorze jours à compter de la réception des biens pour exercer son droit de rétractation sans avoir à justifier de motifs 
                ni à payer de pénalités. 
                Aucun droit de rétractation n'existe notamment pour les produits confectionnés selon les spécifications du client ou nettement personnalisés 
                (c'est le cas notamment de l'ensemble des faux-ongles faits sur-mesure).
                <br /><br />
                Pour bénéficier d'un retour, le produit doit être renvoyé dans son emballage d'origine, il ne doit présenter aucun signe d'usure et n'avoir jamais été utilisé.
                <br /><br />
                Pour effectuer le retour, merci d'envoyer un mail à l'adresse nailsinwonderland.pro@gmail.com en indiquant le motif de votre demande ainsi qu'une preuve d'achat. 
                <br /><br />
                Si votre demande de retour est acceptée nous vous enverrons l'adresse à laquelle renvoyer vos articles.
                <br /><br />
                Les frais de retour sont exclusivement à votre charge.
                <br /><br />
                Les frais de livraisons ne sont pas remboursables. Si vous recevez un remboursement, les frais de livraison seront déduits de celui-ci.
                <br /><br />
                Ne pourront également être repris tous colis pour lesquels aucun élément ne permet d'identifier l'expéditeur (nom, prénom, adresse, numéro de commande).
                <br /><br />
                En cas d'exercice du droit de rétractation dans le délai susvisé, les sommes versées lors de la commande vous seront intégralement remboursées 
                (hors frais de livraison) par virement bancaire en fonction du mode de paiement 
                utilisé lors de la commande en ligne et au plus tard dans les trente jours suivant la date à laquelle le droit de rétractation a été exercé.
            </p>
            <h3>ARTICLE 2 : ANNULATION </h3>
            <p>
                Le contrat produit tous ses effets dès sa conclusion et ne pourra être résilié, 
                sauf utilisation par le client de sa faculté de rétractation dans les conditions mentionnées nés à l'article 1 de la présente politique de remboursement. 
                <br /><br />
                Cependant, si la commande que vous avez passée n'a pas encore été préparée, vous pouvez envoyer un e-mail à l'adresse nailsinwonderland.com pour demander sa modification ou son annulation. 
                <br /><br />
                Vous recevrez alors un retour par e-mail pour vous confirmer si votre demande a pu être prise en compte.
                <br /><br />
                Si la commande est déjà en cours de préparation ou a déjà été expédiée, il ne sera plus possible d'annuler ou de modifier celle-ci.
            </p>
            <h3>ARTICLE 3 : MARCHANDISES NON CONFORMES</h3>
            <p>
                En cas de défauts ou de non-conformité de la marchandise reçue, vous bénéficiez des garanties légales prévues par la législation en vigueur.
                <br /><br />
                Vous pouvez nous contacter aux coordonnées suivantes, par e-mail : nailsinwonderland.pro@gmail.com
                <br /><br />
                Nous examinerons votre demande dans les plus brefs délais et vous contacterons après réception des documents et/ou de votre demande ou réclamation. 
                Vous pouvez faciliter le traitement de votre réclamation en décrivant le plus exactement possible le problème, et en nous transmettant une copie de la facture ou en nous indiquant les références de la commande (le numéro de commande, référence client, etc.). 
            </p>
            <h3>ARTICLE 4 : PERTE OU AVARIE </h3>
            <p>
                <span className='text-underline'>Votre colis n'a pas été livré :</span>
                <br /><br />
                Consultez dans un premier temps les informations de suivi qui vous ont été transmises lors de l'expédition de votre commande.
                <br /><br />
                Si votre colis a été expédié depuis plus de 10 jours ouvrés mais que vous ne l'avez pas réceptionné, 
                vous pouvez nous contacter aux coordonnées suivantes, par e-mail : nailsinwonderland.pro@gmail.com en indiquant le numéro de votre commande.
                <br /><br />
                <br /><br />
                <span className='text-underline'>Votre colis est arrivé détérioré :</span>
                <br /><br />
                A réception du colis, vérifiez qu'il n'ait pas été ouvert, ni endommagé. 
                <br /><br />
                Dans l'hypothèse ou vous auriez un doute, de quelque nature que ce soit, sur l'état du colis ou son contenu, il convient d'appliquer la procédure Colissimo (notamment signaler les dommages constatés, toutes réclamations et réserves) et de refuser la marchandise en émettant immédiatement un constat d'anomalie auprès de La Poste (constat 170).
                Par la suite contactez nous par mail à l'adresse nailsinwonderland.pro@gmail.com.
                <br /><br />
                En priorité, nous vous renverrons les articles manquants ou détériorés.
                <br /><br />
                Si nous ne sommes pas en capacité de renvoyer les articles, par exemple car leur production aurait été arrêtée, vous recevrez un remboursement total ou partiel en fonction du nombre d'articles manquants ou détériorés.
            </p>
        </div>
    )
}

export default Remboursement;