// React
import React from 'react';

// Style
import '../styles/ButtonAddToCart.css';

// Image
import buttonAdd from '../assets/button-add.png';

function ButtonAddToCart({name, price, price_id, images, metadata, cart, updateCart}) {

    function addToCart(name, price, price_id, images, metadata,) {
		const currentproductSaved = cart.find((product) => product.name === name);
		if (currentproductSaved) {
			const cartFilteredCurrentproduct = cart.filter(
				(product) => product.name !== name
			);
			updateCart([
				...cartFilteredCurrentproduct,
				{ name, price, price_id, amount: currentproductSaved.amount + 1, images, metadata },
			]);
			// Mettre à jour le localStorage
			localStorage.setItem('cart', JSON.stringify(cart));
		} else {
			updateCart([...cart, { name, price, price_id, amount: 1, images, metadata }]);
			// Mettre à jour le localStorage
			localStorage.setItem('cart', JSON.stringify(cart));
		}
	}

    return (
        <div className='container'>
            <img src={buttonAdd} alt='button-add' className='button-add' onClick={() => addToCart(name, price, price_id, images, metadata)}></img>
        </div>
    )
}

export default ButtonAddToCart;