// React
import React, { useState, useEffect } from 'react';

// Router
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

// APIs
import { getProducts, getPrices } from '../api/products';

// Style
import './../App.css';

// Components
import Banner from './Banner'
import Sizes from './Sizes';
import ShoppingList from './ShoppingList';
import Filter from './Filter';
import Footer from './Footer';
import Slider from './Slider';
import Conseils from './Conseils';
import MentionsLegales from './MentionsLegales';
import Remboursement from './Remboursement';
import CGV from './CGV';
import Success from './Success';
import DisplayProduct from './DisplayProduct';

function App() {
	const paymentSuccess = localStorage.getItem('paymentSuccess');
	// Essayez de récupérer le panier depuis le localStorage lors du premier rendu
	if (paymentSuccess) {
		// Vider le localStorage
		localStorage.clear();
		// Effacer le marqueur de succès
		localStorage.removeItem('paymentSuccess');
		// Relancer la page
		window.location.reload();
	}

	const [cart, updateCart] = useState(() => {
		const storedCart = localStorage.getItem('cart');
		return storedCart ? JSON.parse(storedCart) : [];
	});
	const [product, setProduct] = useState(() => {
		// Essayez de récupérer le panier depuis le localStorage lors du premier rendu
		const storedProduct = localStorage.getItem('product');
		return storedProduct ? JSON.parse(storedProduct) : [];
	});
	const [allProducts, setAllProducts] = useState([]);
	const [loading, setLoading] = useState(true);
	const [filteredProducts, setFilteredProducts] = useState([]);

	useEffect(() => {
		Promise.all([getProducts(), getPrices()])
			.then((results) => {
				const products = results[0];
				const prices = results[1];
				const productsWithPrices = products.map((product) => {
					const uniqPrice = prices.find((price) => price.id === product.price_id);
					return {
						...product,
						price: uniqPrice ? uniqPrice.unit_amount : 0,
					};
				});
				setAllProducts(productsWithPrices);
				setFilteredProducts(productsWithPrices);
				setLoading(false);
			})
			.catch((error) => {
				console.error('Error fetching products:', error);
				setLoading(false);
			});
	}, [cart]);

	useEffect(() => {
		const storedCart = JSON.parse(localStorage.getItem('cart')) || [];
		updateCart(storedCart);
	}, []);

	return (
		<Router>
			<div>
				<div>
					<Routes>
						<Route
							path="/"
							element={
								<>
									<Banner cart={cart} updateCart={updateCart}></Banner>
									<Slider></Slider>
									<Filter products={allProducts} filteredProducts={filteredProducts} setFilteredProducts={setFilteredProducts} ></Filter>
									<div>
										<ShoppingList products={filteredProducts} cart={cart} updateCart={updateCart} setProduct={setProduct} loading={loading} />
									</div>
									<Footer />
								</>
							}
						/>
						<Route path="/product" element={
							<>
								<Banner cart={cart} updateCart={updateCart}></Banner>
								<DisplayProduct product={product} cart={cart} updateCart={updateCart} />
								<Footer />
							</>} />
						<Route path="/guide-des-tailles" element={
							<>
								<Banner cart={cart} updateCart={updateCart}></Banner>
								<Sizes />
								<Footer />
							</>} />
						<Route path="/conseils" element={
							<>
								<Banner cart={cart} updateCart={updateCart}></Banner>
								<Conseils />
								<Footer />
							</>} />
						<Route path="/mentions-legales" element={
							<>
								<Banner cart={cart} updateCart={updateCart}></Banner>
								<MentionsLegales />
								<Footer />
							</>} />
						<Route path="/cgv" element={
							<>
								<Banner cart={cart} updateCart={updateCart}></Banner>
								<CGV />
								<Footer />
							</>} />
						<Route path="/politique-de-remboursement" element={
							<>
								<Banner cart={cart} updateCart={updateCart}></Banner>
								<Remboursement />
								<Footer />
							</>} />
						<Route path="/success" element={<Success />} />
					</Routes>
				</div>
			</div>
		</Router>
	);

}

export default App;
