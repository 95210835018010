// Style
import '../styles/Infos.css';

function MentionsLegales() {
    return (
        <div className='center-content'>
            <h1>Mentions légales</h1>
            <h3>Identification de l'éditeur</h3>
            <p>
                Editeur : Le Site nailsinwonderland.com est édité et exploité par l'entreprise individuelle Nails In Wonderland
                <br /><br />
                Adresse : 7 impasse guidotti, 06300 Nice
                <br /><br />
                E-mail : nailsinwonderland.pro@gmail.com
                <br /><br />
                N° SIREN : 983854142
                <br /><br />
                N° SIRET : 98385414200012
            </p>
            <h3>Directeur de la publication</h3>
            <p>
                « Au sens de l'article 93-2 de la loi n° 82-652 du 29 juillet 1982. »
                <br /><br />
                Madame Elodie Mendel, directrice de Nails In Wonderland.
            </p>
            <h3>Prestataire d'hébergement</h3>
            <p>
                Pour nailsinwonderland.com
                <br /><br />
                Hostinger
                <br /><br />
                61 Lordou Vironos str. 6023 Larnaca, Chypre
                <br /><br />
                E-mail : gdpr@hostinger.com
            </p>
            <h3>Traitement des données à caractère personnel</h3>
            <p>
                Les informations recueillies par lors d'une commande sur le site nailsinwonderland.com sont enregistrées dans un fichier informatisé par l'entreprise Nails In Wonderland.
                Les données collectées seront communiquées aux seuls destinataires suivants : Tous représentants de l'entreprise Nails In Wonderland.
                Elles sont conservées pendant une période minimale de 10 ans, en application du code de commerce.
            </p>
            <h3>Propriété intellectuelle</h3>
            <p>
            Toute utilisation, reproduction, diffusion, commercialisation, modification de toute ou partie du site nailsinwonderland.com, 
            notamment son contenu (produits listés, descriptions, images, vidéos... ), sans autorisation de l'Editeur est prohibée et pourra entraîner des actions 
            et poursuites judiciaires telles que notamment prévues par le Code de la propriété intellectuelle et le Code civil.
            </p>
        </div>
    )
}

export default MentionsLegales;