export const detailsProduct = {
    pressonnails: {
        text: `
                <li>1 set de 10 faux ongles</li>
                <li>1 boîte de rangement</li>
                <li>1 lime</li>
                <li>1 bâton de buis</li>
                <li>2 feuilles de gel pad (4 utilisations)</li>
                <li>4 feuilles à alcool</li>
               `
    },
    sizingkit: {
        text: `
                <li>1 set de 12 faux ongles</li>
                <li>1 boîte de rangement</li>
                <li>1 carte pour indiquer vos tailles pour chaque ongles de chaque mains</li>
            `
    }
}