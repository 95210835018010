// React
import React, { useState, useEffect } from 'react';

// Navigate
import { useNavigate } from 'react-router-dom';

// Icons
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faTimes } from '@fortawesome/free-solid-svg-icons';

// Style
import '../styles/Banner.css';

// Images
import logoWeb from '../assets/logo-web.png';

// Component
import Cart from './Cart';

function Banner({ cart, updateCart }) {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);
  const [icon, setIcon] = useState(faBars);
  const isMobile = window.innerWidth <= 768;

  useEffect(() => {
    setIcon(isOpen ? faTimes : faBars);
  }, [isOpen]);

  function handleClickHome() {
    window.scrollTo(0, 0);
    navigate('/');
  }

  function handleClickSize() {
    window.scrollTo(0, 0);
    navigate('/guide-des-tailles');
  }

  function handleClickConseils() {
    window.scrollTo(0, 0);
    navigate('/conseils');
  }

  function handleClickMenu() {
    setIsOpen(!isOpen);
  }

  return (
    <>
      <div className='nw-banner'>
        <img src={logoWeb} alt='name-logo' className='logo-web' onClick={handleClickHome}></img>
        {isMobile && (
          <h1 className='title' onClick={handleClickHome}>Nails In Wonderland</h1>
        )
        }
        <div className='menu'>
          <h3 className='nw-nav' onClick={handleClickHome}>
            Boutique
          </h3>
          <h3 className='nw-nav' onClick={handleClickSize}>
            Guide des tailles
          </h3>
          <h3 className='nw-nav' onClick={handleClickConseils}>
            Conseils d'utilisations
          </h3>
        </div>
        <Cart cart={cart} updateCart={updateCart} />
        <div>
          {isMobile && (
            <button className='bars-menu' onClick={handleClickMenu}>
              <FontAwesomeIcon icon={icon} />
            </button>
          )}
        </div>
      </div>
      {isMobile && isOpen && (
        <div className='menu-mobile'>
          <h3 className='nw-nav-mobile' onClick={handleClickHome}>
            Boutique
          </h3>
          <h3 className='nw-nav-mobile' onClick={handleClickSize}>
            Guide des tailles
          </h3>
          <h3 className='nw-nav-mobile' onClick={handleClickConseils}>
            Conseils d'utilisations
          </h3>
        </div>
      )}
    </>
  );
}

export default Banner;
