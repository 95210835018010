import presentation from '../assets/presentation.png'
import reseaux from '../assets/reseaux.png'

export const imageCarousel = [
    {
        id: 1,
        image: presentation
    },
    {
        id: 2,
        image: reseaux
    }
]