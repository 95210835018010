
// React
import React, { useEffect, useState } from 'react';

// Style
import '../styles/DisplayProduct.css'

// Component
import ButtonAddToCart from './ButtonAddToCart';

// Data
import { detailsProduct } from '../datas/detailsProduct';

function DisplayProduct({ product, cart, updateCart }) {
    const [details, setDetails] = useState('');

    useEffect(() => {
        const metadataEntries = Object.entries(product.metadata);
        if(metadataEntries.some(([key, val]) => key === 'pressonnails')){
            setDetails(detailsProduct.pressonnails.text);
        } else if(metadataEntries.some(([key, val]) => key === 'sizingkit')) {
            setDetails(detailsProduct.sizingkit.text);
        }
    }, [product]);

    return (
        <div className="nw-details">
            <div className="nw-product">
                <img className='nw-item-cover' src={product.images[0]} alt={product.name} />
                <h2 className='nw-name-product'>{product.name}</h2>
                <p className='nw-price-product'>{(product.price / 100).toFixed(2)} €</p>
                <ButtonAddToCart name={product.name} price={product.price} price_id={product.price_id} images={product.images} metadata={product.metadata} cart={cart} updateCart={updateCart} />
            </div>
            <div className="infos">
                { product.description && <div>
                    <div className='list-title'>
                        Description
                    </div>
                    <div className='list-details'>
                        {product.description}
                    </div>
                </div> }
                { details && <div>
                    <div className='list-title'>
                        Contenu
                    </div>
                    <div className='list-details' dangerouslySetInnerHTML={{ __html: details }}></div>
                </div> }
                <div>
                    <div className='list-title'>
                        Livraison
                    </div>
                    <div className='list-details'>
                        Uniquement en France. Livraison offerte à partir de 50 € d'achat.
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DisplayProduct;