// Style
import '../styles/Infos.css';

function CGV() {
    return (
        <div className='center-content'>
            <h1>Conditions générales de vente</h1>
            <p>
                Version en vigueur à compter du 11 mars 2024.
            </p>
            <h3>Article 1 - Objet</h3>
            <p>                
                Les présentes conditions générales de vente s'appliquent à toutes les ventes de produits effectuées 
                par Nails In Wonderland sur le site internet nailsinwonderland.com à des clients non professionnels.
            </p>
            <h3>Article 2 - Produits</h3>
            <p>
            Un descriptif de chaque produit sur le site nailsinwonderland.com est indiqué dans la fiche produit.
            Les produits proposés à la vente sont ceux qui figurent sur le site internet nailsinwonderland.com, dans la limite des stocks disponibles. 
            Les produits sont fais mains de ce fait certains produits ne peuvent être exactement identique à la photographies proposée sur le Site.
            </p>
            <h3>Article 3 - Prix</h3>
            <p>                
                Les prix des produits sont indiqués en euros toutes taxes comprises (TTC), hors frais de livraison. 
                Les frais de livraison sont précisés lors de la validation de la commande et sont à la charge du client.
            </p>
            <h3>Article 4 - Commande</h3>
            <p>                
                Le client passe commande en ligne sur le site internet nailsinwonderland.com. 
                La commande est confirmée par l'envoi d'un e-mail de confirmation à l'adresse fournie par le client lors de la commande.
            </p>
            <h3>Article 5 - Paiement</h3>
            <p>                
                Le paiement s'effectue en ligne au moment de la commande par carte bancaire ou tout autre moyen de paiement sécurisé accepté par Nails In Wonderland.
            </p>
            <h3>Article 6 - Livraison</h3>
            <p>                
                Les produits commandés sont livrés à l'adresse indiquée par le client lors de la commande. 
                Les délais de livraison sont indiqués lors de la validation de la commande et sont donnés à titre indicatif.
            </p>
            <h3>Article 7 - Droit de rétractation</h3>
            <p>                
                Conformément aux dispositions légales en vigueur, le client dispose d'un délai de 14 jours à compter de la réception des produits pour exercer son droit de rétractation, sans avoir à justifier de motifs ni à payer de pénalités.
            </p>
            <h3>Article 8 - Garantie</h3>
            <p>                
             Tous les produits bénéficient de la garantie légale de conformité et de la garantie des vices cachés, conformément aux dispositions légales en vigueur.
            </p>
            <h3>Article 9 - Responsabilité</h3>
            <p>                
             La responsabilité de Nails In Wonderland ne saurait être engagée en cas d'inexécution ou de mauvaise exécution du contrat due à un cas de force majeure.
            </p>
            <h3>Article 10 - Litiges</h3>
            <p>                
             En cas de litige, le client peut contacter Nails In Wonderland par e-mail à l'adresse nailsinwonderland.pro@gmail.com.
            </p>
            <h3>Article 11 - Modifications des CGV</h3>
            <p>                
                Nails In Wonderland se réserve le droit de modifier les présentes conditions générales de vente à tout moment. 
                Les conditions générales applicables sont celles en vigueur à la date de la commande.
            </p>
        </div>
    )
}

export default CGV;