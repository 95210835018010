// React
import React, { useEffect } from 'react';

// Navigate
import { useNavigate } from 'react-router-dom';

// Spinner
import { BeatLoader } from 'react-spinners';

// Images
import logoWeb from '../assets/logo-web.png';
import logoMobile from '../assets/logo-web-little.png';

// Style
import "../styles/Success.css";

function Success() {
    const navigate = useNavigate();
    const isMobile = window.innerWidth <= 768;

    useEffect(() => {
        localStorage.setItem('paymentSuccess', true);

        // Rediriger vers la route principale après 5 secondes
        const timeoutId = setTimeout(() => {
            window.scrollTo(0, 0);
            navigate('/');
        }, 5000);

        // Nettoyer le timeout lorsque le composant est démonté
        return () => clearTimeout(timeoutId);
    }, [navigate]);

    return (
        <>
            <div className='div-success'>
                <img src={logoWeb} alt='name-logo' className='logo-web'></img>
                {isMobile && (
            <img src={logoMobile} alt='name-logo' className='logo-mobile'></img>
            )}
                <h2>Paiement réussi !</h2>
                <p className='p-success'>Merci pour votre achat ! Vous allez recevoir un mail de confirmation de commande.</p>
                <p className='p-success'>Vous allez être redirigé automatiquement vers la page d'accueil</p>
                <br /><br />
                <BeatLoader color="#9D8099" />
            </div>
        </>
    );
}

export default Success;
