import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBagShopping } from '@fortawesome/free-solid-svg-icons';
import { faTrash } from '@fortawesome/free-solid-svg-icons';

// Style
import '../styles/Cart.css';

function Cart({ cart, updateCart }) {
  const [isOpen, setIsOpen] = useState(false);
  const [nbItem, setNbItem] = useState(0);
  const showHideClassName = isOpen ? 'nw-cart display-block' : 'nw-cart display-none';
  const total = cart.reduce((acc, productType) => acc + productType.amount * productType.price, 0);

  useEffect(() => {
    // Mettre à jour le localStorage
    localStorage.setItem('cart', JSON.stringify(cart));
    // Calculer le nombre total d'articles
    const newNbItem = cart.reduce((acc, item) => acc + item.amount, 0);
    setNbItem(newNbItem);
  }, [cart]);

  return isOpen ? (
    <div className={showHideClassName}>
      <button className='nw-cart-toggle-button' onClick={() => setIsOpen(false)}>
        <div className='nw-cart-close'>
          <h2>Fermer</h2>
        </div>
      </button>
      {cart.length > 0 ? (
        <div>
          <div className='nw-cart-header'>
            <h2>Panier</h2>
          </div>
          <div className='cart-list-container'>
            <ul>
              {cart.map(({ name, price, amount, images }, index) => (
                <div className='nw-glob-cart' key={`${name}-${index}`}>
                  <div className='nw-item-cart'>
                    <img className='nw-item-cover-cart' src={images} alt={`${name} cover`} />
                    <div className='nw-item-cart'>
                      {name} {(price / 100).toFixed(2)}€ x {amount}
                    </div>
                  </div>
                  <div className='div-quantity' key={`${name}-${index}`}>
                    <h3 className='text-quantity'>Quantité: </h3>
                    <button
                      className='button-quantity'
                      onClick={() => {
                        if (cart[index].amount > 1) {
                          const updatedCart = [
                            ...cart.slice(0, index),
                            { ...cart[index], amount: cart[index].amount - 1 },
                            ...cart.slice(index + 1)
                          ];
                          updateCart(updatedCart);
                        } else {
                          const updatedCart = [...cart.slice(0, index), ...cart.slice(index + 1)];
                          updateCart(updatedCart);
                        }
                      }}
                    >
                      -
                    </button>
                    <input
                      type="number"
                      value={amount}
                      onChange={(e) => {
                        const newAmount = parseInt(e.target.value, 10) || 0;
                        const updatedCart = [
                          ...cart.slice(0, index),
                          { ...cart[index], amount: newAmount },
                          ...cart.slice(index + 1)
                        ];
                        updateCart(updatedCart);
                      }}
                    />
                    <button
                      className='button-quantity'
                      onClick={() => {
                        const updatedCart = [
                          ...cart.slice(0, index),
                          { ...cart[index], amount: cart[index].amount + 1 },
                          ...cart.slice(index + 1)
                        ];
                        updateCart(updatedCart);
                      }}
                    >
                      +
                    </button>
                    <button
                      className='button-quantity'
                      onClick={() => {
                        const updatedCart = [...cart.slice(0, index), ...cart.slice(index + 1)];
                        updateCart(updatedCart);
                      }}
                    >
                      <FontAwesomeIcon icon={faTrash} />
                    </button>
                  </div>
                </div>
              ))}
            </ul>
          </div>
          <div className='nw-cart-total'>
            <h3>Total : {(total / 100).toFixed(2)}€</h3>
            <div className='nw-cart-products'>
              {nbItem} Produit(s)
            </div>
          </div>
          <div className='cart-footer'>
            <form action="https://nailsinwonderland.com/back/checkout.php" method="POST">
              <input type="hidden" name="cart" value={JSON.stringify(cart)} />
              <button className='button-valid' type="submit">
                Payer
              </button>
            </form>
          </div>
        </div>
      ) : (
        <div className='nw-cart-header'>Votre panier est vide</div>
      )}
    </div>
  ) : (
    <div className='nw-cart-closed'>
      <button className='nw-cart-toggle-button' onClick={() => setIsOpen(true)}>
        <FontAwesomeIcon icon={faBagShopping} />
      </button>
      <div className='cart-badge' onClick={() => setIsOpen(true)}>{nbItem}</div>
    </div>
  );
}

export default Cart;
