// Style
import '../styles/Item.css';

// Navigate
import { useNavigate } from 'react-router-dom';

function Item({ images, name, price, metadata, description, setProduct }) {
  const formattedPrice = (price / 100).toFixed(2);
  const navigate = useNavigate();

	function handleClickDisplayProduct() {
    // Mettre à jour le localStorage
		localStorage.setItem('product', JSON.stringify({ images, name, price, metadata, description }));
    setProduct({ images, name, price, metadata, description });
    window.scrollTo(0, 0);
		navigate('/product');
	}

  return (
    <li className='nw-item' onClick={() => handleClickDisplayProduct()}>
      <img className='nw-item-cover' src={images[0]} alt={`${name} cover`} />
      <span className='nw-item-bottom'>{name}</span>
      <span className='nw-item-bottom'>{formattedPrice}€</span>
    </li>
  );
}

export default Item;
