// Style
import '../styles/Infos.css';

function Sizes() {
    return (
        <div className='center-content'>
            <h1>Comment prendre la taille de ses ongles ?</h1>
            <h3>1 - SIZING KIT</h3>
            <p>Il est au prix de 5.90 € et est disponible en forme amande courte.
                Vous pouvez sélectionner le filtre sizing kit pour vous aider.
                <br/><br/>
                Votre ongle doit-être naturel (sans vernis ou autre pose). La capsule doit recouvrir la plaque de votre ongle dans sa largeur sans exercer de pression.
                Il vaut mieux prendre une capsule légèrement plus grande qu'une capsule légèrement plus petite.
                <br/><br/>
                Lorsque vous indiquez vos mesures au moment de la commande, procédez comme suit :
                <br/>
                Main droite, du pouce à l'auriculaire<br/>
                1, 4, 3, 8, 10
                <br/><br/>
                Main gauche, du pouce à l'auriculaire<br/>
                0, 5, 3, 7, 10
            </p>
            <h3>2 - MESURES MANUELLES</h3>
            <p><span className='text-underline'>MATÉRIEL</span>
            <br/><br/>
                . Scotch / Mètre ruban<br/>
                . Règle<br/>
                . Feuille de papier<br/>
                . Stylo<br/>
                <br/><br/>
                <span className='text-underline'>PROTOCOLE</span>
                <br/><br/>
                Pour chaque ongle de chaque main, placez un bout de scotch sur la largeur de votre ongle et appuyez bien pour qu’il adhère à toute la surface de votre ongle naturel
                <br/><br/>
                Tracez ensuite des traits aux deux extrémités les plus larges de votre ongle.
                <br/><br/>
                Placez le scotch à plat sur votre feuille, puis mesurez la distance entre les traits avec votre règle. Les mesures se font dans la largeur.
                <br/><br/>
                Reportez les tailles de chacun de vos ongles sur une feuille en millimètres. Les mesures se prennent au demi millimètre près.
                <br/><br/>
                Lorsque vous indiquez vos tailles au moment de la commande, procédez comme suit :
                <br/><br/>
                Main droite, du pouce à l'auriculaire<br/>
                16mm, 13,5mm, 14,5mm, 12mm, 10mm
                <br/><br/>
                Main gauche, du pouce à l'auriculaire<br/>
                16mm, 13mm, 14,5mm, 13mm, 10mm
            </p>
            <h4>
                Dans les deux cas : 
                <br/>              
                Si les mesures ne correspondent pas, aucun retour ni remboursement ne sera possible.
                Le sizing kit reste plus précis.
            </h4>
        </div>
    )
};

export default Sizes;