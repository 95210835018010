const stripe = require('stripe')('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');

async function getProducts() {
    try {
        const products = await stripe.products.list({ active: true, limit: 50 });
        const simplifiedProducts = products.data.map(product => ({
            id: product.id,
            name: product.name,
            description: product.description,
            price_id: product.default_price,
            price: product.default_price,
            images: product.images,
            metadata: product.metadata
        }));
        return simplifiedProducts;
    } catch (error) {
        console.error(error);
        throw new Error('Erreur lors de la récupération des produits.');
    }
}

async function getPrices() {
    try {
        const prices = await stripe.prices.list({ active: true, limit: 50 });
        const pricesList = prices.data.map(prices => ({
            id: prices.id,
            unit_amount: prices.unit_amount
        }));
        return pricesList;
    } catch (error) {
        console.error(error);
        throw new Error('Erreur lors de la récupération des prix.');
    }
}

module.exports = {
    getProducts,
    getPrices
};