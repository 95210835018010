// React
import React from 'react';

// Navigate
import { useNavigate } from 'react-router-dom';

// Style
import '../styles/Footer.css'

function Footer() {
	const navigate = useNavigate();

	function handleClickMentions() {
		window.scrollTo(0, 0);
		navigate('/mentions-legales');
	}

	function handleClickCGV() {
		window.scrollTo(0, 0);
		navigate('/cgv');
	}

	function handleClickRemboursement() {
		window.scrollTo(0, 0);
		navigate('/politique-de-remboursement');
	}

	return (
		<footer className='nw-footer'>
			<div className='nw-div'>
				<h4 className='nw-nav-footer' onClick={() => handleClickRemboursement()}>Politique de remboursement</h4>
				<h4 className='nw-nav-footer' onClick={() => handleClickMentions()}>Mentions légales</h4>
				<h4 className='nw-nav-footer' onClick={() => handleClickCGV()}>Conditions générales de vente</h4>
			</div>
			<div className='nw-copyright'>
				<p className='nw-p'>&copy; 2024 Nails In Wonderland. Tous droits réservés.</p>
			</div>
		</footer>
	)
}

export default Footer