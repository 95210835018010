// React
import React from 'react';

// Components
import Item from './Item';

// Spinner
import { BounceLoader } from 'react-spinners';

// Style
import '../styles/ShoppingList.css';

// Component
import ButtonAddToCart from './ButtonAddToCart';

function ShoppingList({ products, cart, updateCart, setProduct, loading }) {	

	if (loading) {
		return (
			<div>
				<p className='load-text'>Chargement des produits...</p>
				<BounceLoader className='nw-spinner' color="#9D8099" />
			</div>
		);
	} else {
		return (
			<div className='nw-shopping-list'>
				<ul className='nw-list'>
					{products.map(({ id, images, name, price, price_id, metadata, description }) =>
						<div key={id}>
							<Item images={images} name={name} price={price} metadata={metadata} description={description} setProduct={setProduct} />
							<ButtonAddToCart name={name} price={price} price_id={price_id} images={images} metadata={metadata} cart={cart} updateCart={updateCart} />
						</div>
					)}
				</ul>
			</div>
		);
	}}

export default ShoppingList;
