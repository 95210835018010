// Style
import '../styles/Infos.css';

function Conseils() {
    return (
        <div className='center-content'>
            <h1>Conseils d’utilisations</h1>
            <h3>1 - Pose de Press-On-Nails ( 10 min )</h3>
            <p>
                <span className='text-underline'>Préparation de l'ongle:</span><br /><br />
                . Limez vos ongles (assez court pour une meilleure adhésion)<br />
                . Repoussez vos cuticules à l'aide du bâton de buis (cette étape ne doit pas faire mal)<br />
                . Griffez légèrement la plaque de vos ongles dans la longueur (sens de pousse de l'ongle)<br />
                .Dégraissez vos ongles avec les pads d'alcool (essayer de ne pas toucher vos ongles après cette étape)<br /><br />
                
                <span className='text-underline'>Application de la capsule :</span><br /><br />
                . Appliquez le gel pad sur la plaque de vos ongles sans touche les cuticules (elle doit recouvrir au maximum votre plaque mais elle ne doit pas dépasser)<br />
                . Retirez le film dessus le gel pad<br />
                . Appliquez la capsule sur le gel pad, maintenez 15 sec tout en appuyant sur toute votre plaque pour que tous les côtés soient bien adhérant.<br /><br />
                Terminé !<br />
                Votre pose tiendra entre 5 à 7 jours !<br /><br />
            </p>
            <h3>2 - Dépose (20 min)</h3>
            <p>
                . Trempez vos mains dans de l'eau chaude, environ 10 min. Pour ramolir le gel pad. (N'abime pas les capsules)<br />
                . Utilisez le baton de buis pour décoller la capsule par les côtés. (Vous pouvez utiliser de l'huile à cuticule pour assouplir un peu plus le gel pad)<br />
                . S'il reste du gel pad sur vos ongles ou la capsule retirez le avec le bâton de buis.<br /><br />

                Et voilà !
            </p>
        </div>
    )
};

export default Conseils;