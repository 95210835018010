// React
import React, { useState, useEffect } from 'react';

// Select
import Select from 'react-select';

// Style
import '../styles/Filter.css';

const Filter = ({ products, filteredProducts, setFilteredProducts }) => {
    const [selectedFilter, setSelectedFilter] = useState('allProducts');
    const [selectedSort, setSelectedSort] = useState('');
    const [optionsFilter, setOptionsFilter] = useState([]);
    const [optionsSort, setOptionsSort] = useState([]);
    const isMobile = window.innerWidth <= 768;

    const customStyles = {
        control: (base) => ({
            ...base,
            background: "#393D3A"
        }),
        menuList: base => ({
            ...base,
            background: "#393D3A",
        }),
        singleValue: (base) => ({
            ...base,
            color: "#EEECF2"
        })
    };

    useEffect(() => {
        let filteredProducts = [...products];

        // Filtrer par taille
        if (selectedFilter !== 'allProducts') {
            filteredProducts = products.filter((item) => {
                const metadataEntries = Object.entries(item.metadata);
                return metadataEntries.some(([key, val]) => key === selectedFilter);
            });
        }

        // Trier
        if (selectedSort) {
            switch (selectedSort) {
                case 'bestSelling':
                    filteredProducts.sort((a, b) => b.amount - a.amount);
                    break;
                case 'newest':
                    filteredProducts.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
                    break;
                case 'priceAsc':
                    filteredProducts.sort((a, b) => a.price - b.price);
                    break;
                case 'priceDesc':
                    filteredProducts.sort((a, b) => b.price - a.price);
                    break;
                case 'alphaAsc':
                    filteredProducts.sort((a, b) => a.name.localeCompare(b.name));
                    break;
                case 'alphaDesc':
                    filteredProducts.sort((a, b) => b.name.localeCompare(a.name));
                    break;
                default:
                    break;
            }
        }
        setFilteredProducts(filteredProducts);

        // Options Filter
        const optionsFilterSet = new Set();

        const optionsFilter = products.flatMap((item) =>
          Object.entries(item.metadata).map(([sizeKey, sizeLabel]) => {
            const option = {
              value: sizeKey,
              label: sizeLabel,
            };
        
            // Check if the value is already in the set
            if (!optionsFilterSet.has(option.value)) {
              // Add the value to the set
              optionsFilterSet.add(option.value);
              // Return the option only if it's unique
              return option;
            }
        
            // Return null for duplicate values
            return null;
          })
        );
        
        // Remove null values (duplicates) from the array
        const filteredOptionsFilter = optionsFilter.filter((option) => option !== null);

        setOptionsFilter([{ value: 'allProducts', label: 'Tous les produits' }, ...filteredOptionsFilter]);

        // Options Sort
        const optionssort = [
            { value: 'priceAsc', label: 'Prix croissant' },
            { value: 'priceDesc', label: 'Prix décroissant' },
            { value: 'alphaAsc', label: 'A-Z' },
            { value: 'alphaDesc', label: 'Z-A' }
        ];
        setOptionsSort(optionssort);
    }, [selectedFilter, selectedSort, products, setFilteredProducts]);

    function handleFilterChange(selectedOption) {
        setSelectedFilter(selectedOption.value);
    }

    function handleSortChange(selectedSort) {
        setSelectedSort(selectedSort.value);
    }

    return (
        <>
        <div className="filter-section">
            <div className="filter-container">
                <label className='my-label' htmlFor="filterBy">Filtrer par :</label>
                <Select
                    value={optionsFilter.find((option) => option.value === selectedFilter)}
                    options={optionsFilter}
                    onChange={handleFilterChange}
                    className='my-select'
                    theme={(theme) => ({
                        ...theme,
                        borderRadius: 0,
                        colors: {
                            ...theme.colors,
                            text: '#EEECF2',
                            primary25: '#9D8099',
                            primary: '#291527',
                        },
                    })}
                    styles={customStyles}
                />
            </div>

            <div className="filter-container">
                <label className='my-label' htmlFor="sortBy">Trier par :</label>
                <Select
                    value={optionsSort.find((option) => option.value === selectedSort)}
                    options={optionsSort}
                    onChange={handleSortChange}
                    className='my-select'
                    theme={(theme) => ({
                        ...theme,
                        borderRadius: 0,
                        colors: {
                            ...theme.colors,
                            text: '#EEECF2',
                            primary25: '#9D8099',
                            primary: '#291527',
                        },
                    })}
                    styles={customStyles}
                />
            </div>
            <div className="div-prod">{filteredProducts.length} Produits</div>
        </div>
        { isMobile && <div className="nw-prod">{filteredProducts.length} Produits</div>}
        </>
    );
};

export default Filter;
