// React
import React from "react";

// Carousel
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";

// Style
import "../styles/Slider.css";

// Data
import { imageCarousel } from "../datas/imageCarousel";

function Slider() {
  return (
    <Carousel interval={4000} autoPlay infiniteLoop>
      {imageCarousel.map((slide) => (
        <div key={slide.id}>
          <img src={slide.image} alt="" />
        </div>
      ))}
    </Carousel>
  );
}

export default Slider;